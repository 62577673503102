export const SIREN_SPINE: string = 'siren-spine'
export const SIREN_ATTACK1: string = 'siren-attack1'
export const SIREN_ATTACK2: string = 'siren-attack2'
export const SIREN_ATTACK3: string = 'siren-attack3'
export const SIREN_DAMAGE: string = 'siren-damage'
export const SIREN_DEAD: string = 'siren-dead'
export const ENEMY_SPINE: string = 'enemy-spine'
export const ENEMY_ATTACK1: string = 'enemy-attack1'
export const ENEMY_ATTACK2: string = 'enemy-attack2'
export const ENEMY_ATTACK3: string = 'enemy-attack3'
export const ENEMY_DAMAGE: string = 'enemy-damage'
export const ENEMY_DEAD: string = 'enemy-dead'
export const ENEMY_1_SPINE: string = 'enemy1-spine'
export const ENEMY_1_ATTACK1: string = 'enemy1-attack1'
export const ENEMY_1_ATTACK2: string = 'enemy1-attack2'
export const ENEMY_1_ATTACK3: string = 'enemy1-attack3'
export const ENEMY_1_DAMAGE: string = 'enemy1-damage'
export const ENEMY_1_DEAD: string = 'enemy1-dead'

export const RENA_IDLE: string = 'rena-idle'
export const RENA_ATTACK1: string = 'rena-attack1'
export const RENA_ATTACK2: string = 'rena-attack2'
export const RENA_ATTACK3: string = 'rena-attack3'
export const RENA_DAMAGE: string = 'rena-damage'
export const RENA_DEAD: string = 'rena-dead'
export const NINJA_WATER: string = 'ninja-water'

export const WEAPON: string = 'weapon'